import HDNavigation from '../hd-ui-kit/HDNavigation';
import HDHeader from '../hd-ui-kit/HDHeader';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import defaultLogo from '../assets/img/hns-logo.png';
import { useOktaAuth } from '@okta/okta-react';
import { routes } from '../routes/routes';
import { useGetMeQuery } from '../store/api/v2/auth';
import { useGetClientByIdQuery } from '../store/api/clients';
import { restrictedHandler } from '../rbac/Restricted';
import { userTypeHandler } from '../utils/helpers';
import {
  useLazyDownloadAttachmentDetailsQuery,
  useGetLogoUrlMutation,
} from '../store/api/attachments';

function Header() {
  const [isInsightEnabled, setIsInsightEnabled] = React.useState(false);
  const [logoUrl, setLogoUrl] = React.useState<string>('');
  const { oktaAuth } = useOktaAuth();

  const navigate = useNavigate();
  const params = useParams();
  const pathName = window.location.pathname;
  const isSubNav = pathName.includes('/company');

  const { data: userData } = useGetMeQuery();
  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    params.id as string,
    {
      skip: !isSubNav || !params.id,
    }
  );
  const [downloadAttachment] = useLazyDownloadAttachmentDetailsQuery();
  const [getUrlLogo] = useGetLogoUrlMutation();

  const myPersonas = userData?.data?.myPersonas;

  React.useEffect(() => {
    // check if company has insights enabled, then show them Assessments Tab
    if (clientData?.[0]?.applications?.insights?.isEnabled) {
      setIsInsightEnabled(true);
    } else {
      setIsInsightEnabled(false);
    }
  }, [clientData]);

  const getAttachmentLogoUrl = async (attachmentId: string) => {
    if (attachmentId.length) {
      try {
        await downloadAttachment({ attachmentId }).unwrap();
        const urlLogoResponse = await getUrlLogo({ attachmentId }).unwrap();
        const logoUrl = urlLogoResponse?.data?.s3_url;
        if (logoUrl?.length) setLogoUrl(logoUrl);
      } catch (e) {
        console.log(e);
      }
    }
  };

  React.useEffect(() => {
    const logoAttachmentId =
      (clientData && clientData[0]?.applications?.journey?.logoAttachmentId) ||
      '';
    if (logoAttachmentId.length) {
      getAttachmentLogoUrl(logoAttachmentId);
    }
    if (!logoAttachmentId.length && logoUrl.length) {
      setLogoUrl('');
    }
  }, [clientData]);

  const logoClick = () => {
    navigate(routes.companies.url);
  };

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const onNavClick = (url: string) => {
    if (url === 'signOut') {
      logout();
    } else {
      navigate(url);
    }
  };

  const subNav = [
    restrictedHandler('project.page', userData?.permissionsArr) && {
      url: `${routes.company.url}${params.id}${routes.company.projects.url}`,
      className: pathName.includes(routes.company.projects.url) ? 'yes' : '',
      itemName: routes.company.projects.name,
    },
    restrictedHandler('client.assessments.read', userData?.permissionsArr) && {
      url: `${routes.company.url}${params.id}${routes.company.newassessment.url}`,
      className:
        !pathName.includes(routes.company.projects.url) &&
        pathName.includes(routes.company.newassessment.url)
          ? 'yes'
          : '',
      itemName: routes.company.newassessment.name,
    },
    userTypeHandler(myPersonas) === 'Global' && {
      url: `${routes.company.url}${params.id}${routes.company.settings.url}`,
      className: pathName.includes(routes.company.settings.url) ? 'yes' : '',
      itemName: routes.company.settings.name,
    },
    userTypeHandler(myPersonas) === 'Global' && {
      url: `${routes.company.url}${params.id}${routes.company.reports.url}`,
      className: pathName.includes(routes.company.reports.url) ? 'yes' : '',
      itemName: routes.company.reports.name,
    },
    userTypeHandler(myPersonas) === 'Global' && {
      url: `${routes.company.url}${params.id}${routes.company.users.url}`,
      className: pathName.includes(routes.company.users.url) ? 'yes' : '',
      itemName: routes.company.users.name,
    },
    userTypeHandler(myPersonas) === 'Global' && {
      url: `${routes.company.url}${params.id}${routes.company.roles.url}`,
      className: pathName.includes(routes.company.roles.url) ? 'yes' : '',
      itemName: routes.company.roles.name,
    },
    userTypeHandler(myPersonas) === 'Global' && {
      url: `${routes.company.url}${params.id}${routes.company.statements.url}`,
      className: pathName.includes(routes.company.statements.url) ? 'yes' : '',
      itemName: routes.company.statements.name,
    },
    userTypeHandler(myPersonas) === 'Global' && {
      url: `${routes.company.url}${params.id}${routes.company.impactgroups.url}`,
      className: pathName.includes(routes.company.impactgroups.url)
        ? 'yes'
        : '',
      itemName: routes.company.impactgroups.name,
    },
    userTypeHandler(myPersonas) === 'Global' && {
      url: `${routes.company.url}${params.id}${routes.company.variableLibrary.url}`,
      className: pathName.includes(routes.company.variableLibrary.url)
        ? 'yes'
        : '',
      itemName: routes.company.variableLibrary.name,
    },
    isInsightEnabled && {
      url: `${routes.company.url}${params.id}${routes.company.assessmentstatus.url}`,
      className: pathName.includes(routes.company.assessmentstatus.url)
        ? 'yes'
        : '',
      itemName: routes.company.assessmentstatus.name,
    },
  ];

  const mainNav = [
    {
      url: routes.companies.url,
      className: pathName.includes(routes.companies.url) ? 'yes' : '',
      itemName: routes.companies.name,
    },
    {
      url: routes.roleLibrary.url,
      className: pathName.includes(routes.roleLibrary.url) ? 'yes' : '',
      itemName: routes.roleLibrary.name,
    },
    {
      url: routes.statementLibrary.url,
      className: pathName.includes(routes.statementLibrary.url) ? 'yes' : '',
      itemName: routes.statementLibrary.name,
    },
    {
      url: routes.admins.url,
      className: pathName.includes(routes.admins.url) ? 'yes' : '',
      itemName: routes.admins.name,
    },
    {
      url: routes.variableLibrary.url,
      className: pathName.includes(routes.variableLibrary.url) ? 'yes' : '',
      itemName: routes.variableLibrary.name,
    },
    // Reports tab hidden from heidrick level but accessible from url.
    // {
    //   url: routes.assessment.url,
    //   className: pathName.includes(routes.assessment.url) ? 'yes' : '',
    //   itemName: routes.assessment.name,
    // },
  ];

  const myAccount = [
    {
      url: 'signOut',
      itemName: 'Sign Out',
    },
  ];

  const getHeaderLogo = () => {
    if (isSubNav) {
      if (logoUrl) {
        return logoUrl;
      }
    }
    return defaultLogo;
  };

  return (
    <div>
      <HDHeader
        logoClick={logoClick}
        logo={getHeaderLogo()}
        subNav={isSubNav}
        subNavName={clientData && clientData[0]?.name}
        logoHeight={'58px'}
        logoWidth={'58px'}
        myPersonas={myPersonas}
      >
        <HDNavigation
          navItems={isSubNav ? subNav : mainNav}
          myAccount={myAccount}
          onNavigationClick={onNavClick}
          userData={userData?.data}
          permissionsArr={userData?.permissionsArr}
        />
      </HDHeader>
    </div>
  );
}

export default Header;
